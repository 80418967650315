import { IKeyword } from 'api/types/_keyword';
import { Option } from 'api/types/_public';
import { international } from 'common/data/country';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import DropdownCategory from 'components/Common/DropdownCategory';
import DropdownCountry from 'components/Common/DropdownCountry';
import LabelWrapper from 'components/Common/LabelWrapper';
import ModalConfirm from 'components/Common/ModalConfirm';
import TableContainer from 'components/Common/TableContainer';
import TooltipHtmlCustom from 'components/Common/TooltipHtmlCustom';
import { useRole } from 'components/Hooks/UserHooks';
import RangeDatePicker from 'components/pickers/RangeDatePicker';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { COLORS_STATUS_KEYWORD_CHART_RANKING, ENUM_STATUS_KEYWORD_RANKING, FREQUENCY_OPTIONS, FREQUENCY_TYPE, KEYWORD_SOURCE_TYPE, STATUS_KEYWORD_CHART_RANKING_OPTIONS, TYPE_SOURCE_CHART_RANKING_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { isHavePermissionRole, ROLES_FOR_APP } from 'helpers/role';
import moment from "moment";
import ModalDetailKeyword from 'pages/Keyword/ModalDetailKeyword';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import NoImage from '../../../assets/images/no-photo-available.png';
import { addConfirmRankKeyword, changeStatusKeywordRank, getAllRankCategoriesNormal, getQueryInfoApproveRankKeyword, getRankKeyword, getRankKeywordSinger as onGetRankKeywordSinger, putKeyword, updateNationalityRankKeywordRaw, updateRankKeywordRaw } from "../../../store/thunks";
import DropdownStatus from 'components/Common/DropdownStatus';
import DropdownOption from 'components/Common/DropdownOption';

const SORT_BY_DEFAULT = 'activity_index';
const ORDER_BY_DEFAULT = 'DESC';
const CATEGORY_DEFAULT = '2';

registerLocale("en", en);
registerLocale("ko", ko);

const ChartRankingSinger = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  const DEFAULT_YESTERDAY = moment().format('Y-MM-DD'); // .subtract(1, 'day')

  const TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG = TYPE_SOURCE_CHART_RANKING_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const NATIONALITY_KOREAN_DEFAULT = international[0]?.countryCode;

  const STATUS_KEYWORD_CHART_RANKING_OPTIONS_LANG = STATUS_KEYWORD_CHART_RANKING_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const FREQUENCY_OPTIONS_LANG = FREQUENCY_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const refTabTopTable = useRef<any>(null);

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 500),
    sort_by: withDefault(StringParam, 'index'),
    order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
    keyword: withDefault(StringParam, ''),
    music_platform: withDefault(StringParam, ''),
    rank_category_id: withDefault(StringParam, CATEGORY_DEFAULT),
    frequency_type: withDefault(StringParam, FREQUENCY_OPTIONS_LANG[0]?.value),
    date: withDefault(StringParam, DEFAULT_YESTERDAY),
    nationality: withDefault(StringParam, NATIONALITY_KOREAN_DEFAULT),
    keyword_status: withDefault(StringParam, ''),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const selectLayoutState = (state: any) => state.Keyword;

  const KeywordProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywords: state.rankKeywordSinger,
      isKeywordSuccess: state.isRankKeywordSingerSuccess,
      isKeywordLoading: state.isRankKeywordSingerLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { keywords, error, isKeywordLoading } = useSelector(KeywordProperties);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [dateSearch, setDateSearch] = useState<string>(query?.date || DEFAULT_YESTERDAY);

  const [nationalitySearch, setNationalitySearch] = useState<Option | null>(query?.nationality ? { label: '', value: query?.nationality } : null);

  const [optionsSelected, setOptionsSelected] = useState<any>([]);

  const [categorySearch, setCategorySearch] = useState<Option | null>(null);

  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_KEYWORD_CHART_RANKING_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.keyword_status)) || null);

  const [frequencySearch, setFrequencySearch] = useState<Option | null>(FREQUENCY_OPTIONS_LANG?.filter((item) => String(item?.value) === String(query?.frequency_type))[0]);

  const [isOpenConfirmKeyword, setIsOpenConfirmKeyword] = useState<boolean>(false);
  const [isConfirmKeywordLoading, setIsConfirmKeywordLoading] = useState<boolean>(false);

  const [optionCategoryDropdown, setOptionCategoryDropdown] = useState<any[]>([]);

  const [itemUpdateKeyword, setItemUpdateKeyword] = useState<IKeyword | null>(null);

  const [keywordDetail, setKeywordDetail] = useState<IKeyword | null | Object>(null);

  const [itemUpdateKeywordRaw, setItemUpdateKeywordRaw] = useState<{ keyword_id: number, keyword: string } | null>(null);
  const [isLoadingUpdateKeywordRaw, setIsLoadingUpdateKeywordRaw] = useState<boolean>(false);

  const [itemUpdateNationalityKeywordRaw, setItemUpdateNationalityKeywordRaw] = useState<{ keyword_id: number | string, keyword: string, nationality: any } | null>(null);
  const [isLoadingUpdateNationalityKeywordRaw, setIsLoadingUpdateNationalityKeywordRaw] = useState<boolean>(false);

  const [queryInfoApprove, setQueryInfoApprove] = useState<{ keyword_confirm_flg: number, confirm_time: string, admin_confirm: any, } | null>(null);

  const searchData = (cate_id = '') => {
    setOptionsSelected((prev: any) => []);
    setQuery({
      ...query,
      page: 1,
      keyword: keywordSearch || '',
      rank_category_id: cate_id || categorySearch?.value || '',
      frequency_type: frequencySearch?.value,
      date: dateSearch,
      sort_by: !query?.music_platform ? 'index' : SORT_BY_DEFAULT,
      nationality: nationalitySearch?.value,
      keyword_status: statusSearch?.value,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      frequency_type: FREQUENCY_OPTIONS_LANG[0]?.value,
      date: DEFAULT_YESTERDAY,
      keyword: '',
      rank_category_id: CATEGORY_DEFAULT,
      music_platform: '',
      nationality: NATIONALITY_KOREAN_DEFAULT,
      sort_by: !query?.music_platform ? 'index' : SORT_BY_DEFAULT,
      order_by: ORDER_BY_DEFAULT,
      keyword_status: '',
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setDateSearch(DEFAULT_YESTERDAY);
    setKeywordSearch("");
    setNationalitySearch({ label: '', value: NATIONALITY_KOREAN_DEFAULT });
    setCategorySearch({ label: '', value: CATEGORY_DEFAULT });
    setStatusSearch(null);
    setFrequencySearch(FREQUENCY_OPTIONS_LANG[0]);
  };


  const handleQueryInfoApprove = async () => {
    try {
      const res: any = await getQueryInfoApproveRankKeyword({
        frequency_type: FREQUENCY_TYPE.DAILY,
        date: query?.date,
      });
      setQueryInfoApprove((prev: any) => ({
        keyword_confirm_flg: res?.data?.keyword_confirm_flg || 0,
        confirm_time: res?.data?.confirm_time || '',
        admin_confirm: res?.data?.admin_confirm,
      }));
    } catch (error: any) {
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  useEffect(() => {
    dispatch(onGetRankKeywordSinger(query));
    if (Number(query?.page) === 1) {
      handleQueryInfoApprove();
    }
  }, [dispatch, query]);

  const changePlatform = (platform_search: any) => {
    const queryNew = {
      ...query,
      rank_diff: '',
      music_platform: platform_search?.value || '',
      sort_by: !platform_search ? 'index' : SORT_BY_DEFAULT,
      time_request: + new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setOptionsSelected((prev: any) => []);
  };

  const handleUpdateNationalityKeywordRaw = async () => {
    try {
      if (!itemUpdateNationalityKeywordRaw) {
        return;
      }
      setIsLoadingUpdateNationalityKeywordRaw((_prev) => true);
      const response: any = await updateNationalityRankKeywordRaw(itemUpdateNationalityKeywordRaw?.keyword_id, { nationality: Number(itemUpdateNationalityKeywordRaw?.nationality?.value) });
      setIsLoadingUpdateNationalityKeywordRaw((_prev) => false);
      if (response?.code === 200) {
        setItemUpdateNationalityKeywordRaw((_prev) => null);
        dispatch(onGetRankKeywordSinger(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdateNationalityKeywordRaw((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }


  const handleUpdateKeywordRaw = async () => {
    try {
      if (!itemUpdateKeywordRaw) {
        return;
      }
      setIsLoadingUpdateKeywordRaw((_prev) => true);
      const response: any = await updateRankKeywordRaw(itemUpdateKeywordRaw?.keyword_id, { keyword: itemUpdateKeywordRaw?.keyword });
      setIsLoadingUpdateKeywordRaw((_prev) => false);
      if (response?.code === 200) {
        setItemUpdateKeywordRaw((_prev) => null);
        dispatch(onGetRankKeywordSinger(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdateKeywordRaw((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    // if (!!isFirstLoadingPageRef.current) {
    //   return;
    // }
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (keywords?.list || [])?.map((item: any) => ({ value: item?.rank_keyword_id, label: item?.rank_keyword_id }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === keywords?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  // End:: handle select table 

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Key'),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: 'text-start align-middle',
        thWidth: 50,
        thComponent: () => (<>
          <div className="form-check" style={{ margin: '8px 0px' }} >
            <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
            <label className="form-check-label" htmlFor={`cell-check-all`}></label>
            {(countRowSelected > 0) && <span className="position-absolute translate-middle badge bg-primary rounded-pill"
              style={{ top: '-7px', left: '35%' }}
            >
              {countRowSelected || 0}
            </span>
            }
          </div>
        </>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <>
              <div>
                <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.rank_keyword_id, label: cell?.row?.original?.rank_keyword_id })} value={cell?.row?.original?.rank_keyword_id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.rank_keyword_id, label: cell?.row?.original?.rank_keyword_id }), query?.page, e)} id={`cell-check-${cell?.row?.original?.rank_keyword_id ?? ''}`} />
                <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.rank_keyword_id ?? ''}`}></label>
              </div>
            </>
          )
        },
      },
      {
        Header: t('Ranking'),
        accessor: "rank",
        filterable: false,
        sortable: false,
        thWidth: 90,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: '60px' }} className="text-center">
              <div className="text-center">{cell?.value}</div>
            </div>
          )
        },
      },
      {
        Header: t('Status'),
        accessor: "keyword_status",
        filterable: false,
        sortable: false,
        thWidth: 90,
        thClass: 'text-center align-middle',
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: '65px' }} className="text-center">
              {!!cell?.value ? <span className={`rounded-pill badge bg-${['success', 'success', 'warning', 'danger'][Number(cell?.value || 0)] || 'success'}`}>
                {STATUS_KEYWORD_CHART_RANKING_OPTIONS_LANG?.find((s) => String(s?.value) === String(item?.keyword_status))?.label || ''}
              </span> : '-'}
            </div>
          )
        },
      },
      {
        Header: t("Keyword"),
        accessor: "keyword",
        filterable: false,
        sortable: false,
        thWidth: 200,
        thClass: "align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <TooltipHtmlCustom html_dom={<img
              alt={item?.keyword || ''}
              loading="lazy" src={item?.image_url || NoImage}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                objectPosition: 'center',
                backgroundColor: '#eaeaea',
                maxWidth: '700px',
                borderRadius: '8px',
              }}
            />}>
              <div className={`cursor-pointer d-flex align-items-center justify-content-between`} style={{ minWidth: "170px", maxWidth: '200px' }} onClick={(e) => {
                e.stopPropagation();
                handleCallKeywordDetail(item);
              }}>
                <div style={{ width: '50px', height: '50px', border: '1px solid rgb(230, 230, 230)', borderRadius: '4px', overflow: 'hidden' }} className="me-2 text-center">
                  <img
                    alt={item?.keyword || ''}
                    src={item?.image_url || NoImage}
                    style={{
                      width: 'auto',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                  />
                </div>
                <div style={{ width: '160px' }} className="position-relative">
                  {cell?.value}
                  <div
                    className="ms-1 cursor-pointer position-absolute top-0 text-center"
                    style={{ right: '5px', width: '30px', borderLeft: '1px solid #eaeaea' }}
                    onClick={(e: any) => {
                      e?.stopPropagation();
                      setItemUpdateKeywordRaw((prev: any) => ({ keyword: item?.keyword || '', keyword_id: item?.rank_keyword_id }))
                    }}
                  >
                    <i className="ri-pencil-fill text-primary"></i>
                  </div>

                </div>
              </div >
            </TooltipHtmlCustom>
          )
        },
      },
      {
        Header: "",
        accessor: "____",
        filterable: false,
        sortable: true,
        thWidth: 100,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>

          </div>
        ),
      },
      ...(!query?.music_platform ? [{
        Header: t("Song count"),
        accessor: "song_count",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      }] : []),
      ...(!query?.music_platform ? [{
        Header: t("Popular Index"),
        accessor: "index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      }] : []),
      {
        Header: t("Activity Index"),
        accessor: "activity_index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      ...(!query?.music_platform ? [{
        Header: t("Platform Index"),
        accessor: "platform_index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      },
      {
        Header: t("Genre Index"),
        accessor: "genre_index",
        filterable: false,
        sortable: true,
        thWidth: 150,
        thClass: "text-end align-middle",
        Cell: (cell: any) => (
          <div className="text-end pe-3" style={{ minWidth: "120px" }}>
            {formatNumberWithCommas(cell?.value)}
          </div>
        ),
      }] : []),

      // {
      //   Header: t("Activity Index"),
      //   accessor: "activity_index",
      //   filterable: false,
      //   sortable: false,
      //   thClass: "text-center align-middle",
      //   thWidth: 320,
      //   thComponent: () => (
      //     <table>
      //       <thead>
      //         <tr>
      //           <th className="text-center pb-1" colSpan={2}>
      //             {t("Activity Index")}
      //           </th>
      //         </tr>
      //         <tr>
      //           <th
      //             className="text-center px-0 py-1 cursor-pointer none-sticky"
      //             style={{ width: "160px" }}
      //             onClick={() => {
      //               handleChangeSorting({ sort_by: 'activity_index', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'activity_index') ? 'ASC' : 'DESC' })
      //             }}
      //           >
      //             {t("Platform Index")}
      //             {query?.sort_by === 'activity_index' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
      //               {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
      //             </span>)}
      //           </th>
      //           <th
      //             className="text-center px-0 py-1 cursor-pointer none-sticky"
      //             style={{ width: "160px" }}
      //             onClick={() => {
      //               handleChangeSorting({ sort_by: 'genre_index', order_by: (query?.order_by === 'DESC' && query?.sort_by === 'genre_index') ? 'ASC' : 'DESC' })
      //             }}
      //           >
      //             {t("Genre Index")}
      //             {query?.sort_by === 'genre_index' && (<span style={{ display: 'inline-flex', justifyContent: 'center', width: '12px', marginLeft: '2px', verticalAlign: 'middle' }}>
      //               {(query?.order_by === 'ASC') ? <i className={`ri-arrow-up-line fs-13 text-primary`} /> : <i className={`ri-arrow-down-line fs-13 text-primary`} />}
      //             </span>)}
      //           </th>
      //         </tr>
      //       </thead>
      //     </table>
      //   ),
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <div className="d-flex text-center">
      //         <div className="align-self-center pe-1" style={{ width: "160px", borderRight: "1px solid #eaeaea" }}>
      //           <>
      //             <div
      //               style={{ minWidth: "150px" }}
      //             >
      //               {formatNumberWithCommas(item?.activity_index)}
      //             </div>
      //           </>
      //         </div>
      //         <div className="align-self-center ps-2" style={{ width: "160px" }}>
      //           <>
      //             <div
      //               style={{ minWidth: "150px" }}
      //             >
      //               {formatNumberWithCommas(item?.genre_index)}
      //             </div>
      //           </>

      //         </div>
      //       </div>
      //     )
      //   },
      // },
      ...(!query?.music_platform ? [
        ...(!!optionCategoryDropdown?.find((c: any) => c?.category_id === query?.rank_category_id)?.allow_search_volume ? [{
          Header: t("Search Index"),
          accessor: "search_index",
          filterable: false,
          sortable: true,
          thWidth: 150,
          thClass: "align-middle text-end",
          Cell: (cell: any) => (
            <div className="text-end pe-3" style={{ minWidth: "120px" }}>
              {formatNumberWithCommas(cell?.value)}
            </div>
          ),
        }] : []),
        {
          Header: t("P Index"),
          accessor: "participation_index",
          filterable: false,
          sortable: true,
          thWidth: 150,
          thClass: "align-middle text-end",
          description: t("Participation Index"),
          Cell: (cell: any) => (
            <div className="text-end pe-3" style={{ minWidth: "120px" }}>
              {formatNumberWithCommas(cell?.value)}
            </div>
          ),
        }] : []),
      ...(query?.music_platform ? ([
        {
          Header: t(""),
          accessor: "__",
          filterable: false,
          sortable: true,
          thWidth: 80,
          thClass: "align-middle text-start",
          Cell: (cell: any) => (
            <div className="text-start pe-3" style={{ minWidth: "120px" }}>

            </div>
          ),
        },
        {
          Header: t("Song"),
          accessor: "song_title",
          filterable: false,
          sortable: true,
          thWidth: 200,
          thClass: "align-middle text-start",
          Cell: (cell: any) => (
            <div className="text-start pe-3" style={{ minWidth: "140px" }}>
              {`${cell?.value || ''}`}
            </div>
          ),
        },
        {
          Header: t("Artist"),
          accessor: "authors",
          filterable: false,
          sortable: true,
          thWidth: 200,
          thClass: "align-middle text-start",
          Cell: (cell: any) => (
            <div className="text-start pe-3" style={{ minWidth: "140px" }}>
              {`${(cell?.value || [])?.map((au: any) => `${au?.name}`).join((', '))}`}
            </div>
          ),
        },
      ]) : []),
      {
        Header: t("Nationality"),
        accessor: "nationality",
        filterable: false,
        sortable: false,
        thWidth: 150,
        thClass: "text-center align-middle",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const nationality = international?.find((nal: any) => String(nal?.countryCode) === String(cell?.value));
          return (
            <div className="text-end" style={{ minWidth: "120px" }}>
              <div className="d-flex align-items-center justify-content-end">
                {nationality ? <div className="d-flex text-center align-items-center justify-content-end" style={{ width: '100px' }}>
                  <img className='me-1' src={nationality?.flagImg} alt={nationality?.countryNameKo} style={{ width: '18px', height: '18px' }} />
                  <div>{(i18n?.language === 'en' ? nationality?.countryName : nationality?.countryNameKo) || '-'}</div>
                </div> : <div className="d-flex text-center align-items-center justify-content-end" style={{ width: '100px' }}>-</div>}
                <div
                  className="ms-3 ps-2 cursor-pointer text-center"
                  style={{ right: '5px', width: '30px', borderLeft: '1px solid #eaeaea' }}
                  onClick={(e: any) => {
                    e?.stopPropagation();
                    setItemUpdateNationalityKeywordRaw((prev: any) => ({
                      nationality: {
                        value: nationality?.countryCode,
                        label: (i18n?.language === 'en') ? nationality?.countryName : nationality?.countryNameKo
                      },
                      keyword_id: item?.rank_keyword_id,
                      keyword: item?.keyword,
                    }));
                  }}
                >
                  <i className="ri-pencil-fill text-primary"></i>
                </div>
              </div>
            </div>
          )
        },
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: 'text-end align-middle',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '120px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
    ],
    [i18n?.language, JSON.stringify(optionsSelected), JSON.stringify(keywords?.list), userPermissions, JSON.stringify(query), JSON.stringify(optionCategoryDropdown)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  const handleChangePicker = (value: any) => {
    setDateSearch((_prev) => value ? moment(value).format("Y-MM-DD") : '');
  }

  const handleChangeDatePicker = (date: any) => {
    const frequencyValue = String(frequencySearch?.value);
    switch (frequencyValue) {
      case String(FREQUENCY_TYPE.MONTHLY):
        handleChangePicker(moment(date).startOf('isoMonth').toDate());
        break;
      case String(FREQUENCY_TYPE.WEEKLY):
        handleChangePicker(moment(date).startOf('isoWeek').toDate());
        break;
      case String(FREQUENCY_TYPE.DAILY):
        handleChangePicker(date);
        break;
      default:
        throw new Error('Invalid frequency type');
    }
  }

  // Begin::Confirm
  const handleConfirmKeyword = () => {
    setIsOpenConfirmKeyword((_prev) => true);
  };

  const handleActionConfirmKeyword = async () => {
    try {
      setIsConfirmKeywordLoading((_prev) => true);
      const dataForm = {
        date: query?.date,
        frequency_type: FREQUENCY_TYPE.DAILY,
      };
      const response: any = await addConfirmRankKeyword(dataForm);
      if (response?.code === 200) {
        dispatch(onGetRankKeywordSinger(query));
        handleQueryInfoApprove();
        setIsConfirmKeywordLoading((_prev) => false);
        setIsOpenConfirmKeyword((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmKeywordLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmKeywordLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmKeywordClick = () => {
    setIsOpenConfirmKeyword((_prev) => false);
  }
  // End::Confirm

  // Begin::Update status
  const [keywordAction, setKeywordAction] = useState<{ status: number } | null>(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const handleConfirmChangeStatus = (item: { status: number }) => {
    setKeywordAction((_prev) => item);
  };

  const handleActionChangeStatus = async () => {
    if (!keywordAction) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const kw_s = (optionsSelected || []).flat()?.map((item: any) => item?.value);
      const response: any = await changeStatusKeywordRank({
        ...keywordAction,
        keyword_ids: kw_s,
        category_id: query?.rank_category_id,
      });
      if (response?.code === 200) {
        dispatch(onGetRankKeywordSinger(query));
        setIsConfirmLoading((_prev) => false);
        setKeywordAction((_prev) => null);
        setOptionsSelected((prev: any) => []);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmChangeStatusClick = () => {
    setKeywordAction((_prev) => null);
  }
  // End::Update status

  const handleUpdateKeyword = async () => {
    try {
      if (!itemUpdateKeyword) {
        return;
      }
      setIsLoading((_prev) => true);
      const response: any = await putKeyword(itemUpdateKeyword?.id, { keyword: itemUpdateKeyword?.keyword });
      setIsLoading((_prev) => false);
      if (response?.code === 200) {
        setItemUpdateKeyword((_prev) => null);
        dispatch(onGetRankKeywordSinger(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleCallKeywordDetail = async (item: IKeyword) => {
    try {
      setKeywordDetail(() => ({ keyword_type: item?.keyword_type }));
      const response: any = await getRankKeyword(item?.rank_keyword_id);
      if (response?.code === 200) {
        setKeywordDetail(() => (response?.data));
      } else {
        setKeywordDetail(() => null);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setKeywordDetail(() => null);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseKeywordDetailClick = () => {
    setKeywordDetail(() => null);
  };

  const handleNextCategory = () => {
    const indexCurrent = optionCategoryDropdown?.findIndex((cate: any) => String(cate?.id) === String(categorySearch?.value));
    const valNext = optionCategoryDropdown?.[indexCurrent + 1] || optionCategoryDropdown[0];
    setCategorySearch((prev) => ({ label: '', value: `${valNext?.id}` }));
    searchData(`${valNext?.id}`);
  };

  useEffect(() => {
    const handleCallAllOption = async () => {
      try {
        const [res1]: any = await Promise.all([getAllRankCategoriesNormal({ rank_category_type: KEYWORD_SOURCE_TYPE.SINGER })]);
        setOptionCategoryDropdown((_prev: any) => (
          (res1?.data || [])?.map((c: any) => ({ ...c, category_id: `${c?.id}` }))
        ));
        if (query?.rank_category_id) {
          setCategorySearch((_prev) => {
            return ({
              label: '',
              value: `${query?.rank_category_id}`,
            });
          });
        }
      } catch (error: any) {
        return error;
      }
    };
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (!query?.rank_category_id) {
      setCategorySearch({ label: t('All Category'), value: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Chart Ranking Singer')} - ${t('Chart Ranking Management')} | Rankify`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Chart Ranking Singer')} pageTitle={t('Chart Ranking Management')} />
          {isHavePermissionRole(ROLES_FOR_APP.CHART_RANKING_SINGER, userPermissions) && (
            <React.Fragment>
              <Row>
                <Col sm={12}>
                  <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                    <div className="ribbon round-shape text-end" style={{ background: 'rgba(40, 99, 213, 1)', fontWeight: 300, fontSize: '0.8rem', top: '11px', borderTopLeftRadius: 'var(--vz-border-radius)', borderBottomLeftRadius: 'var(--vz-border-radius)' }}>
                      {t("Today's data will be available for review after 12 PM")} <br />
                      {t("Today's data will be automatically approved at 3 PM")}
                    </div>
                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                      <Row className="g-4 align-items-center pt-5 pt-sm-0">
                        <div className="col-12 col-sm-3 col-md-2">
                          <h5 className="card-title mb-1">
                            <span className="me-2">{t('Total')}:</span>
                            <CountUp
                              start={0}
                              end={keywords?.list?.length || 0}
                              duration={1}
                              className="text-primary"
                            />
                          </h5>
                        </div>
                        <div className="col-9 col-sm-10 col-md-10 mt-1 mt-sm-4 sm-border-left">
                          <div className="d-flex align-items-center">
                            <div style={{ minWidth: '200px' }}>
                              <div className="fs-12">{t('Approve')}: <strong className="text-primary">{!!Number(queryInfoApprove?.keyword_confirm_flg) ? t('Approved') : t('Pending')}</strong></div>
                              <div className="fs-12">{t('Approver')}: <span className="text-muted">{!!Number(queryInfoApprove?.keyword_confirm_flg) ? (queryInfoApprove?.admin_confirm?.email || '') : '-'}</span></div>
                              <div className="fs-12">{t('Time')}: <span className="text-muted">{!!Number(queryInfoApprove?.keyword_confirm_flg) ? (queryInfoApprove?.confirm_time || '') : '-'}</span></div>
                            </div>
                            {(isHavePermissionRole(ROLES_FOR_APP.RANKING_MONITORING_UPDATE, userPermissions)) && <button
                              type="button"
                              className="btn btn-info ms-3 mt-sm-1"
                              onClick={handleConfirmKeyword}
                              disabled={!!Number(queryInfoApprove?.keyword_confirm_flg)}
                            >
                              <span>{t('Approve')}</span>
                            </button>}
                          </div>
                        </div>
                      </Row>
                    </CardHeader>
                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                      <Row className="g-4 align-items-center mt-0">
                        <Col sm={12} md={6} xl={4} xxl={2} className='mt-3 mt-md-3 mt-xl-2'>
                          <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('Keyword')}...`}
                              value={keywordSearch}
                              onChange={(e) => setKeywordSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={12} md={6} xl={4} xxl={3} className='d-flex mt-3 mt-md-3 mt-xl-2'>
                          <div className="w-100">
                            <LabelWrapper label={t('Category')} isShow={!!categorySearch}>
                              <DropdownCategory
                                name="category"
                                placeholder={t('Category')}
                                isClearable={true}
                                dataList={optionCategoryDropdown || []}
                                initialValue={categorySearch ?? undefined}
                                onChangeCategory={(event) => {
                                  setCategorySearch(event);
                                }}
                              />
                            </LabelWrapper>
                          </div>
                          <button
                            className="btn btn-soft-primary d-inline-block ms-2"
                            onClick={(e) => {
                              e.preventDefault();
                              handleNextCategory();
                            }}
                            style={{ width: '70px' }}
                          >
                            {t('Next')}
                          </button>
                        </Col>
                        <Col sm={12} md={6} xl={4} xxl={2} className='mt-3 mt-md-3 mt-xl-2'>
                          <LabelWrapper label={t('Interval')} isShow={true}>
                            <DropdownOption
                              name="interval"
                              dataList={FREQUENCY_OPTIONS_LANG || []}
                              placeholder={`${t("Interval")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={frequencySearch || FREQUENCY_OPTIONS_LANG[0]}
                              onChangeSelect={(e: any) => setFrequencySearch(e)}
                              isHasOptionAll={false}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={12} md={6} xl={4} xxl={2} className='date-picker-wrapper-custom z-hight mt-3 mt-md-3 mt-xl-2'>
                          <LabelWrapper label={t('Date')} isShow={!!dateSearch}>
                            <RangeDatePicker
                              maxDate={moment().toDate()}
                              frequencyTypeSearch={frequencySearch}
                              dateSearch={dateSearch}
                              handleChangeDatePicker={handleChangeDatePicker}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={12} md={6} xl={4} xxl={3} className=' mt-3 mt-md-3 mt-xl-2'>
                          <LabelWrapper label={t('Status')} isShow={true}>
                            <DropdownStatus
                              name="status"
                              dataList={STATUS_KEYWORD_CHART_RANKING_OPTIONS_LANG || []}
                              placeholder={`${t("Status")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={statusSearch || null}
                              onChangeSelect={(e: any) => setStatusSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All Status'), value: '' }}
                              otherColor="dark"
                              colors={COLORS_STATUS_KEYWORD_CHART_RANKING}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={12} md={6} xl={4} xxl={2} className='mt-3 mt-md-3'>
                          <LabelWrapper label={t('Nationality')} isShow={true}>
                            <DropdownCountry
                              name="nationality"
                              placeholder={`${t("Nationality")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={nationalitySearch || null}
                              onChangeSelect={(event: any) => setNationalitySearch(event)}
                              isClearable={false}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All Nationality'), value: '' }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={12} md={12} xl={12} xxl={10} className="hstack gap-1 justify-content-center justify-content-md-end justify-content-xxl-end mt-3 mt-md-2">
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-2 "
                              onClick={() => searchData()}
                              disabled={isKeywordLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t('Button Search')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t('Button Reset')}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-1 sticky-menu-tab-top-table" ref={refTabTopTable}>
                      <Row>
                        <Col sm={12} md={12} lg={8} xl={7} className="my-2">
                          <ul className="nav nav-tabs nav-tabs-custom nav-primary nav-justified nav nav-tabs" style={{ maxWidth: '500px', borderBottom: '0px solid #fff' }}>
                            <li className="nav-item"><a className={`nav-link cursor-pointer ${!query?.rank_diff && String(query?.music_platform || '') === '' ? 'active' : ''}`} role="button" href="/"
                              onClick={(e: any) => {
                                e.preventDefault();
                                changePlatform(null);
                              }}
                            >{t('Tab Total')}</a></li>
                            <li className="nav-item"><a className={`nav-link cursor-pointer ${String(query?.music_platform || '') === String(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[0]?.value) ? 'active' : ''}`} role="button" href="/" onClick={(e: any) => {
                              e.preventDefault();
                              changePlatform(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[0]);
                            }}
                            >{t('Tab Melon')}</a></li>
                            <li className="nav-item"><a className={`nav-link cursor-pointer ${String(query?.music_platform || '') === String(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[1]?.value) ? 'active' : ''}`} role="button" href="/"
                              onClick={(e: any) => {
                                e.preventDefault();
                                changePlatform(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[1]);
                              }}
                            >{t('Tab VIBE')}</a></li>
                            <li className="nav-item"><a className={`nav-link cursor-pointer ${String(query?.music_platform || '') === String(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[2]?.value) ? 'active' : ''}`} role="button" href="/"
                              onClick={(e: any) => {
                                e.preventDefault();
                                changePlatform(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[2]);
                              }}
                            >{t('Tab Genie')}</a></li>
                            <li className="nav-item"><a className={`nav-link cursor-pointer ${String(query?.music_platform || '') === String(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[3]?.value) ? 'active' : ''}`} role="button" href="/"
                              onClick={(e: any) => {
                                e.preventDefault();
                                changePlatform(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[3]);
                              }}
                            >{t('Tab Bugs')}</a>
                            </li>
                            <li className="nav-item"><a className={`nav-link cursor-pointer ${String(query?.music_platform || '') === String(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[4]?.value) ? 'active' : ''}`} role="button" href="/"
                              onClick={(e: any) => {
                                e.preventDefault();
                                changePlatform(TYPE_SOURCE_CHART_RANKING_OPTIONS_LANG[4]);
                              }}
                            >{t('Tab FLO')}</a>
                            </li>
                          </ul>
                        </Col>
                        <Col sm={12} md={12} lg={4} xl={5} className="my-2 text-end ">
                          {isHavePermissionRole(ROLES_FOR_APP.RANKING_MONITORING_UPDATE, userPermissions) && (!query?.music_platform) && <div className="px-3">
                            <button
                              type="button"
                              className="btn btn-soft-warning ms-2 fs-14"
                              style={{ width: '90px' }}
                              disabled={countRowSelected <= 0}
                              onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatus({ status: ENUM_STATUS_KEYWORD_RANKING.HIDDEN }) }}
                            >
                              {t('Hide')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-soft-danger ms-2 fs-14"
                              style={{ width: '90px' }}
                              disabled={countRowSelected <= 0}
                              onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatus({ status: ENUM_STATUS_KEYWORD_RANKING.EXCLUDED }) }}
                            >
                              {t('Exclude')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-soft-success ms-2 fs-14"
                              style={{ width: '90px' }}
                              disabled={countRowSelected <= 0}
                              onClick={(e) => { e.stopPropagation(); handleConfirmChangeStatus({ status: ENUM_STATUS_KEYWORD_RANKING.ACTIVATED }) }}
                            >
                              {t('Activate')}
                            </button>
                          </div>}
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-3">
                      <TableContainer
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle table-bordered-dashed sticky-table-chart-ranking-singer"
                        theadClass="table-light text-muted"
                        columns={columns}
                        data={keywords?.list?.length ? keywords?.list : []}
                        textNoData={''}
                        customPageSize={query.limit}
                        customPageIndex={query.page - 1}
                        totalRecords={keywords?.list?.length}
                        customPageCount={Math.ceil(Number(keywords?.list?.length) / Number(query?.limit))}
                        handleChangePage={handleChangePage}
                        manualSorting={true}
                        sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                        handleChangeSorting={handleChangeSorting}
                        isLoading={isKeywordLoading}
                        plusPaddingTopFixed={window.innerWidth >= 1000 ? refTabTopTable?.current?.clientHeight : 0}
                        isShowPagination={false}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <ToastContainer closeButton={false} limit={1} />

              <Modal
                isOpen={!!itemUpdateNationalityKeywordRaw?.keyword_id}
                centered={true}
                size="md"
                toggle={() => setItemUpdateNationalityKeywordRaw((_prev) => null)}
                keyboard={true}
              >
                <ModalHeader toggle={() => setItemUpdateNationalityKeywordRaw((_prev) => null)}>
                  {t('Button Update Nationality')} - {itemUpdateNationalityKeywordRaw?.keyword || ''}
                </ModalHeader>
                <ModalBody className="">
                  <Col md={12} lg={12}>
                    <div>
                      <label className="form-label">
                        {t("Nationality")}
                      </label>
                      <DropdownCountry
                        name="nationality"
                        placeholder={`${t("Nationality")}...`}
                        className="dropdown-status-rounded z-hight"
                        classNamePrefix="name-prefix"
                        initialValue={itemUpdateNationalityKeywordRaw?.nationality}
                        onChangeSelect={(event: any) => setItemUpdateNationalityKeywordRaw((prev: any) => ({ ...prev, nationality: event }))}
                        isClearable={false}
                        isHasOptionAll={false}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-4">
                      <button
                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                        color="light"
                        type="button"
                        style={{ width: '100px' }}
                        onClick={() => { !isLoadingUpdateNationalityKeywordRaw && setItemUpdateNationalityKeywordRaw((_prev) => null) }}
                      >
                        {t("Button Close")}
                      </button>
                      <button
                        className="btn btn-primary rounded-pill fs-14"
                        color="success"
                        type="button"
                        disabled={isLoadingUpdateNationalityKeywordRaw}
                        style={{ width: '150px' }}
                        onClick={() => handleUpdateNationalityKeywordRaw()}
                      >
                        {isLoadingUpdateNationalityKeywordRaw ? (
                          <Spinner size="sm" className="me-2"></Spinner>
                        ) :
                          <> {t("Button Update")}</>}
                      </button>
                    </div>
                  </Col>
                </ModalBody>
              </Modal>

              <Modal
                isOpen={itemUpdateKeyword !== null}
                centered={true}
                size="md"
                toggle={() => setItemUpdateKeyword((_prev) => null)}
                keyboard={true}
              >
                <ModalHeader toggle={() => setItemUpdateKeyword((_prev) => null)}>
                  {t('Button Update Keyword')}
                </ModalHeader>
                <ModalBody className="">
                  <Col md={12} lg={12}>
                    <div>
                      <label className="form-label">
                        {t("Keyword")}
                        <span className="text-danger"> *</span>
                      </label>
                      <Input
                        type="textarea"
                        rows={2}
                        id="keyword"
                        name="keyword"
                        autocomplete={false}
                        value={itemUpdateKeyword?.keyword}
                        placeholder={`${t("Keyword")}...`}
                        onChange={(event: any) => {
                          setItemUpdateKeyword((prev: any) => ({ ...prev, keyword: event?.target?.value || '' }))
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-3">
                      <button
                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                        color="light"
                        type="button"
                        style={{ width: '100px' }}
                        onClick={() => { !isLoading && setItemUpdateKeyword((_prev) => null) }}
                      >
                        {t("Button Close")}
                      </button>
                      <button
                        className="btn btn-primary rounded-pill fs-14"
                        color="success"
                        type="button"
                        disabled={isLoading}
                        style={{ width: '150px' }}
                        onClick={() => handleUpdateKeyword()}
                      >
                        {isLoading ? (
                          <Spinner size="sm" className="me-2"></Spinner>
                        ) :
                          <> {t("Button Update")}</>}
                      </button>
                    </div>
                  </Col>
                </ModalBody>
              </Modal>


              <ModalDetailKeyword
                header={t("Keyword Description")}
                isShow={!!keywordDetail}
                keyword={keywordDetail}
                onClose={onCloseKeywordDetailClick}
              />
              <ModalConfirm
                header={t('Approve')}
                textButtonConfirm={t('Approve')}
                classButtonConfirm={'btn-soft-info'}
                title={<div dangerouslySetInnerHTML={{ __html: `${t('Do you want to approve the [INTERVAL] rankings for [DATE]').replace('[DATE]', `<span class='d-inline-block'>${query?.date || ''}</span>`).replace('[INTERVAL]', query?.frequency_type === String(FREQUENCY_TYPE.DAILY) ? t('daily') : (query?.frequency_type === String(FREQUENCY_TYPE.WEEKLY) ? t('weekly') : ''))}?` }} />}
                content={t('Upon approval, the ranking data will be published.')}
                isOpen={isOpenConfirmKeyword}
                classIconButtonConfirm=''
                isShowIcon={false}
                isLoading={isConfirmKeywordLoading}
                onClose={onCloseConfirmKeywordClick}
                onConfirm={handleActionConfirmKeyword}
              />
              <Modal
                isOpen={itemUpdateKeywordRaw !== null}
                centered={true}
                size="md"
                toggle={() => setItemUpdateKeywordRaw((_prev) => null)}
                keyboard={true}
              >
                <ModalHeader toggle={() => setItemUpdateKeywordRaw((_prev) => null)}>
                  {t('Button Update')}
                </ModalHeader>
                <ModalBody className="pt-3">
                  <Col md={12} lg={12}>
                    <div>
                      <label className="form-label">
                        {t("Keyword")}
                        <span className="text-danger"> *</span>
                      </label>
                      <Input
                        id="KeywordRaw"
                        name="KeywordRaw"
                        autocomplete={false}
                        value={itemUpdateKeywordRaw?.keyword}
                        placeholder={`${t("Keyword")}...`}
                        onChange={(event: any) => {
                          setItemUpdateKeywordRaw((prev: any) => ({ ...prev, keyword: event?.target?.value || '' }))
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="hstack gap-2 justify-content-sm-center justify-content-md-end mt-4">
                      <button
                        className="btn btn-soft-secondary rounded-pill fs-14 me-2"
                        color="light"
                        type="button"
                        disabled={isLoadingUpdateKeywordRaw}
                        style={{ width: '100px' }}
                        onClick={() => setItemUpdateKeywordRaw((_prev) => null)}
                      >
                        {t("Button Close")}
                      </button>
                      <button
                        className="btn btn-primary rounded-pill fs-14"
                        color="success"
                        type="button"
                        disabled={isLoadingUpdateKeywordRaw}
                        style={{ width: '120px' }}
                        onClick={() => handleUpdateKeywordRaw()}
                      >
                        {isLoadingUpdateKeywordRaw ? (
                          <Spinner size="sm" className="me-2"></Spinner>
                        ) :
                          <> {t("Button Update")}</>}
                      </button>
                    </div>
                  </Col>
                </ModalBody>
              </Modal>

              <ModalConfirm
                header={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? t('Hide') : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? t('Activate') : t('Exclude'))}
                textButtonConfirm={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? t('Hide') : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? t('Activate') : t('Exclude'))}
                classButtonConfirm={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? 'btn-warning' : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? 'btn-success' : 'btn-danger')}
                title={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? `${t('Do you want to hide these keywords on the date [DATE]').replace('[DATE]', query?.date || '')}?` : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? `${t('Are you sure you want to activate this')}?` : `${t('Are you sure you want to exclude this keyword')}?`)}
                content={String(keywordAction?.status) === String(ENUM_STATUS_KEYWORD_RANKING.HIDDEN) ? t('When hidden, it will not appear in the rankings, and the changes will be reflected in about 20 minutes.') : ([String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_EXCLUDED), String(ENUM_STATUS_KEYWORD_RANKING.CANCEL_HIDDEN), String(ENUM_STATUS_KEYWORD_RANKING.ACTIVATED)].includes(String(keywordAction?.status)) ? t('After confirming the activate action, the data will be permanently activated and cannot be recovered. Do you want to proceed with the activate action.') : t('When excluded, it will not appear in the rankings, and the changes will be reflected in about 20 minutes.'))}
                isOpen={keywordAction !== null}
                classIconButtonConfirm=''
                isShowIcon={false}
                isLoading={isConfirmLoading}
                onClose={onCloseConfirmChangeStatusClick}
                onConfirm={handleActionChangeStatus}
              />
            </React.Fragment>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChartRankingSinger;

